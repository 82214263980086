<template>
  <div class="container is-component">
    <div class="head-top">
      <div class="header">
        <div class="header-container">
          <h1 class="h1-nav-logo">
            <a>
              <img src="@/assets/image/head-left.png" class="nav-logo" />
            </a>
          </h1>
          <h1 class="h1-nav-kefu">
            <a>
              <img src="@/assets/image/head-right.png" class="nav-kefu" 
              @mouseenter="showFloatingDiv = true"
              @mouseleave="setTimeoutDiv()"
              />
            </a>
          </h1>
        </div>
      </div>
    </div>
    <div class="foot-bottom">
      <div class="foot">
        <div class="foot-container">
          <h1 class="foot-container-h1">
            <img src="@/assets/image/foot-right.png" height="80px" width="auto"/>
          </h1>
        </div>
        <div class="foot-security-information">
          <div class="foot-security-information-container">
            <div class="information-left">
                <img src="@/assets/image/foot-left.png" height="80px" width="auto"/>
              
            </div>
            <div class="information-right">
              <div class="information-text-p">
                <p>
                  <a href="https://beian.miit.gov.cn/" rel="nofollow">
                    <i class="lazy gov-icon gov-icon-icp" data-original="//static.tukuppt.com/common/image/govemblem.png"></i>
                    <span>浙ICP备2024097876号-1</span></a>
                    <span class="tukuppt-right">杭州无界千寻网络科技有限公司-Copyright © 2021 版权所有</span>
                </p>
                
                    <p> 电话：15116109528   邮箱： 876408472@qq.com</p>
                    <p>地址：浙江省杭州市滨江区浦沿街道滨文路426号岩大房文苑大厦20楼203132室</p>

                <!-- <p><span>关于我们</span><span>关于我们</span><span>关于我们</span><span>关于我们</span></p>
                <p><span>商务合作邮箱:email@email.com 备案号公安备案:增值电信业务经营许可证浙ICP备2024097876号-1</span></p> -->
              </div>
            </div>
          </div> 
        </div>
      </div>

      <div v-if="showFloatingDiv" class="popup" id="myPopup">
        <img src="@/assets/image/pop-up.png"/>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  created() {
    this.checkDevice();
    window.addEventListener('resize', this.checkDevice);
  },
  destroyed() {
    window.removeEventListener('resize', this.checkDevice);
  },
  data(){
    return{
      showFloatingDiv:false
    }
  },
  methods : {
    setTimeoutDiv(){
      setTimeout(()=>{
        this.showFloatingDiv = false
      },2000)
    },
    checkDevice() {
      if (window.innerWidth <= 1024) {
        window.location.href = 'http://m1.qx5j.com/'
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
img {
    overflow-clip-margin: content-box;
    overflow: clip;
}

/* 设置顶部悬浮的头部 */
.head-top {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  /* 其他样式 */
  height: 120px;
}
.header{
    height: 120px;
    color: #fff;
    top: 0;
    left: 0;
    width: 100%;
    line-height: 120px;
    z-index: 98;
    position: relative;
}
.header-container{
  padding: 0 12px;
  width: 1140px;
  margin: 0 auto;
}
.header .h1-nav-logo {
    margin: 0;
    float: left;
    font-size: 32px;
    font-weight: 400;
    text-decoration: none;
    display: block;
    height: 120px;
}
.nav-logo {
    vertical-align: sub;
    height: 120px;
    width: auto;
}

.header .h1-nav-kefu {
    margin: 0;
    float: right;
    font-size: 32px;
    font-weight: 400;
    text-decoration: none;
    display: block;
    height: 120px;
}
.nav-kefu {
    float: right;
    vertical-align: sub;
    height: 50px;
    width: auto;
    line-height: 50px;
    margin-top: 35px;
}
 
/* 设置底部悬浮的尾部 */
.foot-bottom {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 99;
  /* 其他样式 */
  height: 200px;
}
.foot{
  width: 100%;
  /* line-height: 200px; */
  z-index: 98;
  position: relative;
}
.foot-container{
  padding: 0px 5px 0px 12px;
  width: 1200px;
  height: 80px;
  margin: 0 auto;
}
.foot-container-h1{
  display: block;
  height: 80px;
  line-height: 80px;
  margin: 0;
  padding: 0;
  float: right;
}
.foot-security-information{
  padding: 0px 5px 0px 12px;
  height: 120px;
  margin: 0 auto;
  background-color: rgb(29, 32, 37,0.4);
  /* background-color: #333; */
  /* opacity: 0.4; */
  background-blend-mode: multiply;
  display: flex;
  justify-content: center;
  align-items: center;
}
.foot-security-information-container{
    display: flex;
    width: 1000px;
    height: 120px;
    justify-content: flex-start;
}
.information-left{
    display: flex;
    width: 160px;
    height: 120px;
    align-items: center;
}
.information-right{
    display: flex;
    width: 800px;
    height: 120px;
    align-items: center;
    color: #fff;
}
.information-text-p{
    width: 800px;
    padding: 0;
    margin: 0px;
    margin-left: 5px;
    display: block;
}
.information-text-p p {
  text-align: center;
  line-height: 30px;
  margin: 0px;
  padding: 0px;
  color: #7d7d7d;
  font-size: 14px
}
a {
  padding: 0 8pt;
  font-size: 12px;
  text-decoration:none;
  color: #7d7d7d;
}
.popup {
    width: 300px;
    /* padding: 20px;
    background-color: #fff; */
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* visibility: hidden;  */
    /* opacity: 0; */
    transition: visibility 0s, opacity 0.5s linear;
    z-index: 150;
  }
  .popup img{
    width: 100%;
    border-radius: 5%;
  }
</style>
